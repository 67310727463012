import React from 'react'

import { LoginFlow, RegistrationFlow } from '@ory/kratos-client'
import { AxiosResponse } from 'axios'
import { getOryFrontendClient } from 'src/clients/oryClient'
import styled from 'styled-components'

import { ErrorEventTypes, EventClickNamesValues } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { usePostLogin } from '~/hooks/useAuth'
import { OryAuthParams, useOryFlows } from '~/hooks/useOryFlows'
import { useUserAgentInfo } from '~/hooks/useUserAgentInfo'
import { useUTMSessionStorage } from '~/hooks/useUtmSessionStorage'
import logger from '~/utils/logger'
import { getOrySubmitUrl } from '~/utils/oryUtils'

type SocialAuthFormProps = {
  screenName: 'LOGIN' | 'REGISTER'
  // in case of merge account, loginFlow will be passed
  // loginFlow is response from oryFrontendClient.getLoginFlow({ id: flowId})
  // flowId is the merge account flow id
  // based on the flow data, we can decide which social auth providers to show
  accountLinkAuthParams?: OryAuthParams
}

type SocialAuthProvider = 'google' | 'facebook' | 'apple'

const getClickName = ({
  authProvider,
  screenName,
}: {
  authProvider: SocialAuthProvider
  screenName: SocialAuthFormProps['screenName']
}): EventClickNamesValues => {
  switch (authProvider) {
    case 'google':
      return screenName === 'LOGIN' ? 'login_google' : 'create_account_google'
    case 'facebook':
      return screenName === 'LOGIN' ? 'login_facebook' : 'create_account_facebook'
    case 'apple':
      return screenName === 'LOGIN' ? 'login_apple' : 'create_account_apple'
    default:
      return screenName === 'LOGIN' ? 'login_email_password' : 'create_account_email_signup'
  }
}

export const SocialAuthForm: React.FC<SocialAuthFormProps> = ({ accountLinkAuthParams, screenName }) => {
  const { uaResults } = useUserAgentInfo()
  const { createOryReturnToUrl } = usePostLogin()
  const { processOryFlowResponse, processOryApiError } = useOryFlows()

  const [loading, setLoading] = React.useState(false)
  const { utmParams } = useUTMSessionStorage()

  const { trackCta } = useTrackClick()

  const flowErrorType = screenName === 'REGISTER' ? ErrorEventTypes.AccountRegisterFail : ErrorEventTypes.SignInFail

  const handleFormSubmit = async (clickedProvider: SocialAuthProvider) => {
    try {
      if (loading) return
      setLoading(true)

      trackCta({
        text: '',
        type: 'button',
        destination: 'home',
        click_name: getClickName({ authProvider: clickedProvider, screenName }),
      })

      const oryFrontendClient = getOryFrontendClient()
      const response: AxiosResponse<RegistrationFlow> | AxiosResponse<LoginFlow> = await (async () => {
        if (screenName === 'REGISTER') {
          return oryFrontendClient.createBrowserRegistrationFlow({ returnTo: createOryReturnToUrl() })
        } else if (accountLinkAuthParams?.flowId) {
          return oryFrontendClient.getLoginFlow({ id: accountLinkAuthParams.flowId })
        } else {
          return oryFrontendClient.createBrowserLoginFlow({ returnTo: createOryReturnToUrl() })
        }
      })()
      const loginOrRegistrationFlow = response.data
      const { hasError, authParams } = processOryFlowResponse(loginOrRegistrationFlow, { flowErrorType })
      if (hasError) {
        setLoading(false)
        return
      }
      const orySubmitUrl = getOrySubmitUrl({ submitUrl: authParams.submitUrl, utm: utmParams, uaResults })

      // create a form dynamically and submit it
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = orySubmitUrl
      const providerInput = document.createElement('input')
      providerInput.name = 'provider'
      providerInput.value = clickedProvider
      providerInput.type = 'hidden'
      form.appendChild(providerInput)
      document.body.appendChild(form)
      form.submit()
    } catch (error) {
      logger.error('Error::SocialAuthForm::handleFormSubmit', clickedProvider, error)
      await processOryApiError(error, { flowErrorType })
    } finally {
      setLoading(false)
    }
  }

  // always show all auth options,
  // does not show specific auth options, it its not available in the merge account flow
  const showGoogle = accountLinkAuthParams ? accountLinkAuthParams.showGoogle : true
  const showFacebook = accountLinkAuthParams ? accountLinkAuthParams.showFacebook : true
  const showApple = accountLinkAuthParams ? accountLinkAuthParams.showApple : true

  return (
    <SocialAuthButtons>
      {showGoogle && (
        <AuthInput
          onClick={() => handleFormSubmit('google')}
          src='/assets/images/auth/google-auth-button.svg'
          type='image'
          disabled={loading}
        />
      )}
      {showFacebook && (
        <AuthInput
          onClick={() => handleFormSubmit('facebook')}
          src='/assets/images/auth/facebook-auth-button.svg'
          type='image'
          disabled={loading}
        />
      )}
      {showApple && (
        <AuthInput
          onClick={() => handleFormSubmit('apple')}
          src='/assets/images/auth/apple-auth-button.svg'
          type='image'
          disabled={loading}
        />
      )}
    </SocialAuthButtons>
  )
}

const AuthInput = styled.input`
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
`

const SocialAuthButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
`
