import React from 'react'

import { Caption2SemiBold } from '@lumoslabs/lumosity-storybook'
import Trans from 'next-translate/Trans'
import Link from 'next/link'
import styled from 'styled-components'

type AuthFooterProps = {
  legalAgreementTranslationKey: string
  className?: string
}
export const AuthFooter: React.FC<AuthFooterProps> = ({ legalAgreementTranslationKey, className }) => {
  return (
    <LegalFooter className={className}>
      <Caption2SemiBold>
        <Trans
          i18nKey={legalAgreementTranslationKey}
          components={[
            <Link href='/terms-of-service' key={0} />,
            <Link href='/privacy-policy' key={1} />,
            <Link href='/privacy-policy#what-information-we-collect' key={2} />,
            <br key={3} />,
          ]}
        />
      </Caption2SemiBold>
    </LegalFooter>
  )
}

const LegalFooter = styled.div`
  width: 400px;
  color: ${({ theme }) => theme.colors.coolGray62};
  text-align: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    width: 100%;
    padding: 0 24px;
  }
`
