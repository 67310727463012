import React from 'react'

import { BodyText3, BodyText3SemiBold } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import ButtonLinkWithTracking from '~/components/ButtonLinkWithTracking'

type AlternateAuthFlowRowProps = {
  buttonLabel: string
  buttonHref: string
  prompt?: string
  onClick?: Parameters<typeof ButtonLinkWithTracking>['0']['onClick']
}
export const AlternateAuthFlowRow: React.FC<AlternateAuthFlowRowProps> = ({
  buttonLabel,
  buttonHref,
  prompt,
  onClick,
}) => {
  return (
    <AlternateFlowButtonContainer>
      {prompt ? <BodyText3>{prompt}</BodyText3> : null}
      <AlternateAuthFlowButton href={buttonHref} kind={'secondary'} onClick={onClick}>
        <BodyText3SemiBold>{buttonLabel}</BodyText3SemiBold>
      </AlternateAuthFlowButton>
    </AlternateFlowButtonContainer>
  )
}

const AlternateFlowButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
  margin-top: 8px;

  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    flex-direction: column;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    ${BodyText3} {
      margin-bottom: 0;
    }
  }
`

const AlternateAuthFlowButton = styled(ButtonLinkWithTracking)`
  height: 36px;
  padding: 6px 12px;
  min-height: 0;
`
