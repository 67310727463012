import React from 'react'

import { BodyText1SemiBold, Title1 } from '@lumoslabs/lumosity-storybook'
import Head from 'next/head'
import styled from 'styled-components'

import BrainImage from '~/images/icons/Brand/LumosityCircleColor.svg'
import LumosityText from '~/images/icons/Brand/LumosityText.svg'

type AuthLayoutProps = {
  children: React.ReactNode | React.ReactNode[]
  leftColumnBackgroundImageUrl: string
  headTitle: string
  leftColumnTitle: string
  leftColumnBackgroundColor: string
  mobileBackgroundLinearGradient?: string
  leftColumnSubtitle?: string
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  leftColumnTitle,
  leftColumnSubtitle,
  leftColumnBackgroundImageUrl,
  leftColumnBackgroundColor,
  mobileBackgroundLinearGradient,
  headTitle,
}) => {
  return (
    <PageContainer>
      <Head>
        <title>{headTitle}</title>
      </Head>
      <Main>
        <BrandBackground
          backgroundImageUrl={leftColumnBackgroundImageUrl}
          backgroundColor={leftColumnBackgroundColor}
          mobileLinearGradient={mobileBackgroundLinearGradient}
        />
        <BannerContent>
          <BrandContainer>
            <StyledBrainImage title='lumosity-avatar' />
            <BrandTitle fill='black' title='brand-title' />
          </BrandContainer>
          <TitleContainer>
            <Title1>{leftColumnTitle}</Title1>
            {leftColumnSubtitle && <BodyText1SemiBold>{leftColumnSubtitle}</BodyText1SemiBold>}
          </TitleContainer>
        </BannerContent>
        <FormContent>{children}</FormContent>
      </Main>
    </PageContainer>
  )
}

const tabletMaxWidthPixels = 880

const BrandBackground = styled.div<{
  backgroundImageUrl: string
  backgroundColor: string
  mobileLinearGradient?: string
}>`
  grid-area: 1 / 1 / -1 / 2;
  background-repeat: no-repeat;
  background-size: 120vh;
  background-position: left 42% bottom;
  background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`},
    linear-gradient(160deg, #fff 4.4%, #c7edf2 57.18%, #76d3ff 92.57%);
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media (max-width: ${tabletMaxWidthPixels}px) {
    background-image: ${({ mobileLinearGradient, theme }) => mobileLinearGradient ?? theme.colors.gradient.primary};
    background-size: cover;
  }
`

const BrandTitle = styled(LumosityText)`
  height: 28px;
  width: 110px;
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
`

const BrandContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 40px;
  align-items: center;

  svg:nth-child(2) {
    margin-top: 6px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    padding: 64px 0 0;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  @media (max-width: ${tabletMaxWidthPixels}px) {
    align-items: center;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    display: none;
  }
`

const StyledBrainImage = styled(BrainImage)`
  height: 30px;
  width: 30px;
`

const PageContainer = styled.div`
  min-height: 100vh;
  margin: 0;
  background: ${({ theme }) => theme.colors.gradient.primary};
`

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${({ theme }) => theme.colors.orangeBase};
    text-decoration: none;
  }

  @media (max-width: ${tabletMaxWidthPixels}px) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 4fr 25vh;
  }
`

const BannerContent = styled.div`
  grid-area: 1 / 1 / -1 / 2;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.blackBase};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 13vh;
  padding-left: 8vw;
  padding-right: 8vw;

  @media (max-width: ${tabletMaxWidthPixels}px) {
    grid-area: 1 / 1 / 1 / 1;
    align-items: center;
    padding-top: 4vh;
  }

  ${Title1} {
    color: ${({ theme }) => theme.colors.constant.black};
    max-width: 491px;

    @media (max-width: ${tabletMaxWidthPixels}px) {
      text-align: center;
    }
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    padding: 0;
  }
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.oatBase};
  background-image: ${({ theme }) => theme.colors.gradient.primary};

  @media (max-width: ${tabletMaxWidthPixels}px) {
    grid-area: 2 / 1 / 3 / 2;
    height: 100%;
    padding: 6vw 10vw;
    justify-content: unset;
    background: none;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.tabletMedium} {
    padding: 24px 0 0;
  }
`
